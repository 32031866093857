  import React from "react";
  import xml2js from "xml2js";
  import useAsync from "Hooks/useAsync/useAsync";
  import RidesListData from "Assets/rides.xml";
  import RideItem from "Components/RideItem/RideItem";
  import WaitingTime from "Components/WaitingTime/WaitingTime";
  import useInterval from "Hooks/useInterval/useInterval";
  import useAxios from "axios-hooks";

  const getRideslist = () => {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({
        valueProcessors: [copyrightToSubscript],
      });
      parser
        .parseStringPromise(RidesListData)
        .then(function (result) {
          resolve(result.reportDataList.measurements);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  function copyrightToSubscript(name) {
    return name.replace(/\u00ae/g, "<span class='superscript'>&reg;</span>");
  }

  const RidesList = () => {
    const {
      pending: pendingRidesList,
      value: ridesList,
      error: errorRidesList,
    } = useAsync(getRideslist);
    const [
      {
        data: waitingTimeList,
        loading: pendingWaitingTimeList,
        error: errorWaitingTimeList,
      },
      refetch,
    ] = useAxios({url: "/Default/GetWaitTime"});


    const [sortedWaitingTime, setSortedWaitingTime] = React.useState([])

    useInterval(() => {
      refetch();
    }, 3000);

    const sortOrder = (a, b) => {
      const aHours = a.Hours;
      const bHours = b.Hours;

      const aMinutes = a.Minutes;
      const bMinutes = b.Minutes;

      const aClosedReason = a.ClosedReason;
      const bClosedReason = b.ClosedReason;
      let comparison = 0;
      
      if (aClosedReason && !bClosedReason) {
        comparison = 1
      }
      if (!aClosedReason && bClosedReason) {
        comparison = -1
      }

      if (!aClosedReason && !bClosedReason) {
      if (aHours > bHours) {
        comparison = 1
      }
      if (aHours < bHours) {
        comparison = -1
      }
      if (aHours === bHours) {
        if (aMinutes > bMinutes) {
          comparison = 1;
        } else {
          comparison = -1;
        }
      }
     
    }
      return comparison;
    };

    React.useEffect(() => {
      if (waitingTimeList) {

      const sorted = [...waitingTimeList].sort(sortOrder);
      setSortedWaitingTime( sorted )
      }
    }, [waitingTimeList])
    
    return (
      <div className="rideslist">
          
        {sortedWaitingTime &&
          !errorWaitingTimeList &&
          
          sortedWaitingTime.map((waitingTime, index) => {
            {
              return(
              ridesList &&
                ridesList.map((ride) => {
                  const id = ride.$.resultId;
                  if (id === waitingTime.Id) {
                    return (
                      <div key={id} className="rideitem">
                        <RideItem id={id} name={ride.name}>
                          <WaitingTime waitingTime={waitingTime}></WaitingTime>
                        </RideItem>
                      </div>
                    );
                  }
                }));
            }
          })}
        {
          (pendingWaitingTimeList ? (
            <span className="rideitem__time">Loading...</span>
          ) : (
            ""
          ),
          errorWaitingTimeList ? <span className="rideitem__time">-</span> : "")
        }
        {errorRidesList && "error downloading list of rides"}
        {pendingRidesList && "list of rides is downloading"}
      </div>
    );
  };

  export default RidesList;
