import React from "react";

const WaitingTime = (props) => {
    const { Hours, Minutes, Seconds, ClosedReason } = props.waitingTime;
  const roundedWaitingTime = (Minutes, Seconds, Interval) => {
    if (Seconds >= 30 || Minutes === 0) {
      Minutes++;
    }
    const minutesAsFractionOfFive = Minutes / Interval;
    const fractionRoundedToWholeNumber =
      Math.ceil(minutesAsFractionOfFive) * Interval;
    return fractionRoundedToWholeNumber;
  };

  const getRideData = () => {
    if (ClosedReason) return ClosedReason;
    if (Hours > 0) {
      if (Hours === 1) {
        return `${Hours} hour ${roundedWaitingTime(Minutes, Seconds, 5)} min.`;
      }
      else {
        return `${Hours} hours ${roundedWaitingTime(Minutes, Seconds, 5)} min.`;
      }
    }
    else return `${roundedWaitingTime(Minutes, Seconds, 5)} min.`;
  };

  return <span className="rideitem__time">{getRideData()}</span>;
};

export default WaitingTime;
