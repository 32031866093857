import React from 'react'

const RideItem = props => {
  const { name, id } = props;
  
  function InnerHTML() {
    return <span dangerouslySetInnerHTML={{ __html: name }} />;
  }
  const logoUrl = "Front-end/build/assets/images/" + id + ".png";
  const className = id;
  return (
    <div className="rideitem__wrapper">
      <div className="rideitem__logo">
        <img className={"icon-" + className} src={logoUrl} />
      </div>
      <span className="rideitem__text">
        <InnerHTML className="rideitem__name" html={name} />
        {props.children}
      </span>
    </div>
  );
};

export default RideItem;