import "core-js/es";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import RidesList from "Components/RidesList/RidesList";

const App = () => {
  return (
      <RidesList></RidesList>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
